@use 'style/variables';

.unified-nav {
  & + .lp-main__content {
    width: calc(100% - var(--tempo-unified-nav-width));
  }

  .favorite-item {
    border-left: 6px solid transparent;
    padding-left: 1rem;
    &--sub-section {
      font-size: 90%;
      opacity: 50%;
    }
  }
}

#tempo-dropdown-container {
  .favorite-item {
    border-left: 3px solid transparent;
    padding-left: 0.5rem;
    &--sub-section {
      font-size: 90%;
      opacity: 50%;
    }
  }
}

.hide-sidebar-toggler {
  .tempo-sidebar-toggle-container {
    display: none;
  }
}

#integrations-subnav,
#help-and-support-subnav,
#my-profile-subnav {
  &.tempo-navigation-dropdown-menu {
    position: absolute;
    visibility: hidden;
    width: 250px;
  }
  .external-link {
    display: flex;

    width: 100%;
    &--icon {
      margin-left: auto;
      opacity: 50%;
    }
  }
  .tempo-nav-item--academy {
    border-bottom: 1px solid variables.$tempoNovaGray2;
    &__has-outposts {
      border-top: 1px solid variables.$tempoNovaGray2;
    }
  }
  .my-profile,
  .switch-workspace {
    &--header {
      align-items: center;
      display: flex;
      gap: 7px;
    }

    &--name {
      display: block;
      font-size: 90%;
      opacity: 50%;
      margin-left: 27px;
    }
  }
}

/* This removes margin from the regular nav items so when we swap favorites for the CustomNavigationSection, the spacing is consistent */
.tempo-nav:first-child {
  margin-bottom: 0;
  + .tempo-nav {
    margin-top: 0;
  }
}
